<sl-dynamic-table [config]="tableConfig$()" class="sl-fancy-scroll tia-settings-table-wrapper">

    <sl-custom-column-template column="branchName">
        <ng-template let-rowData>
            @if (rowData.definesDefaultSettings) {
                <span>TIA Defaults for<br>new branches:</span>
            } @else {
                <span>{{ rowData.branchName }}</span>
            }
        </ng-template>
    </sl-custom-column-template>

    <sl-custom-column-template column="tiaOn">
        <ng-template let-rowData>
            <p-selectButton
					styleClass="sl-v1-select-button"
                    [disabled]="!hasChangeTiaPermissionSignal$()"
					[allowEmpty]="false"
					[options]="offOnOptions"
					[ngModel]="rowData.tiaOn"
					optionLabel="label"
					optionValue="value"
                    (onChange)="onTiaOnChange($event.value, !!rowData.definesDefaultSettings, rowData.branchName)">
            </p-selectButton>
        </ng-template>
    </sl-custom-column-template>

    <sl-custom-column-template column="tiaOnForPr">
        <ng-template let-rowData>
            <p-selectButton
					styleClass="sl-v1-select-button"
					[allowEmpty]="false"
                    [disabled]="!hasChangeTiaPermissionSignal$() || (!rowData.tiaOn && !rowData.definesDefaultSettings)"
					[options]="offOnOptions"
					[ngModel]="rowData.tiaOnForPr"
					optionLabel="label"
					optionValue="value"
                    (onChange)="onTiaOnForPrChange($event.value, !!rowData.definesDefaultSettings, rowData.branchName)">
            </p-selectButton>
        </ng-template>
    </sl-custom-column-template>

    <sl-custom-column-template column="activateNextFullRun">
        <ng-template let-rowData>
            @if (rowData.activateNextFullRun != undefined) {
                <p-selectButton
                        styleClass="sl-v1-select-button"
                        [disabled]="!rowData.tiaOn"
                        [allowEmpty]="false"
                        [options]="offOnOptions"
                        [ngModel]="rowData.activateNextFullRun"
                        optionLabel="label"
                        optionValue="value"
                        (onChange)="onActivateNextFullRunChange($event.value, rowData.branchName)">
                </p-selectButton>
            }
        </ng-template>
    </sl-custom-column-template>

    <sl-custom-column-template column="fullRunPolicy">
        <ng-template let-rowData>
            <sl-full-run-policy-dropdown
                [disabled]="!hasChangeTiaPolicyPermissionSignal$() || (!rowData.tiaOn && !rowData.definesDefaultSettings)"
                [value]="{ type: rowData.fullRunPolicy.type, daysBetweenFullRuns: rowData.fullRunPolicy.count }"
                (changed)="onUpdateFullRunPolicy({ type: $event.type, count: $event.daysBetweenFullRuns }, !!rowData.definesDefaultSettings, rowData.branchName)">
            </sl-full-run-policy-dropdown>
        </ng-template>
    </sl-custom-column-template>

    <sl-custom-column-template column="recommendationMode">
        <ng-template let-rowData>
            <p-selectButton
					styleClass="sl-v1-select-button"
					[allowEmpty]="false"
                    [disabled]="!rowData.tiaOn && !rowData.definesDefaultSettings"
					[options]="recommendationModeOptions"
					[ngModel]="rowData.recommendationMode"
					optionLabel="label"
					optionValue="value"
                    (onChange)="onRecommendationModeChange($event.value, !!rowData.definesDefaultSettings, rowData.branchName)">
            </p-selectButton>
        </ng-template>
    </sl-custom-column-template>

</sl-dynamic-table>
