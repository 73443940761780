import { NgIf } from '@angular/common';
import {
	ChangeDetectionStrategy,
	Component,
	input,
	OnChanges,
	output,
	SimpleChanges,
	viewChildren } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SharedModule } from 'primeng/api';
import { OverlayPanel, OverlayPanelModule } from 'primeng/overlaypanel';

import { TiaFullRunPolicyDescriptionPipe } from '@@shared/tia-aux-pipe';

import { FullRunPolicy, TiaCiPolicyType, TiaManualPolicyType } from '../../models/tia-settings.model';

@Component({
	selector: 'sl-full-run-policy-dropdown',
	templateUrl: './full-run-policy-dropdown.component.html',
	styleUrl: './full-run-policy-dropdown.component.scss',
	standalone: true,
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [
		MatTooltipModule,
		OverlayPanelModule,
		SharedModule,
		NgIf,
		FormsModule,
		TiaFullRunPolicyDescriptionPipe,
	]
})
export class FullRunPolicyDropdownComponent implements OnChanges {
	valueSignal$ = input<FullRunPolicy>(null, { alias: 'value' });
	disabledSignal$ = input<boolean>(false, { alias: 'disabled' });

	readonly changed = output<FullRunPolicy>();

	readonly overlayPanelSignal$ = viewChildren<OverlayPanel>('op');

	label = '';
	isOpen = false;
	readonly DEFAULT_NUMBER_OF_DAYS = 1;
	daysBetweenFullRunsTestRunsInputValue = this.DEFAULT_NUMBER_OF_DAYS;
	daysBetweenFullRunsMonthInputValue = this.DEFAULT_NUMBER_OF_DAYS;
	value: FullRunPolicy;
	readonly TiaCiPolicyType = TiaCiPolicyType;
	readonly TiaManualPolicyType = TiaManualPolicyType;

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.valueSignal$?.currentValue) {
			this.value = changes.valueSignal$.currentValue as FullRunPolicy;

			switch (this.value.type) {
				case TiaCiPolicyType.Daily:
					this.label = 'Daily: on first run';
					break;
				case TiaManualPolicyType.Weekly:
					this.label = 'Weekly: on first run';
					break;
				case TiaManualPolicyType.Monthly:
					this.label = 'Monthly: on first run';
					break;
				case TiaManualPolicyType.EveryXTestRuns:
					this.label = `Every ${this.valueSignal$().daysBetweenFullRuns} test stage runs`;
					this.daysBetweenFullRunsTestRunsInputValue = Number(this.value?.daysBetweenFullRuns) || this.DEFAULT_NUMBER_OF_DAYS;
					break;
				case TiaManualPolicyType.EveryXMonth:
					this.label = `Every ${this.valueSignal$().daysBetweenFullRuns} month`;
					this.daysBetweenFullRunsMonthInputValue = Number(this.value?.daysBetweenFullRuns) || this.DEFAULT_NUMBER_OF_DAYS;
					break;
			}
		}
	}

	selectPolicyEveryXTime(type: TiaManualPolicyType.EveryXTestRuns | TiaManualPolicyType.EveryXMonth, closePanel = true): void {
		let days: number;

		switch (type) {
			case TiaManualPolicyType.EveryXTestRuns:
				days = Number(this.daysBetweenFullRunsTestRunsInputValue);
				break;
			case TiaManualPolicyType.EveryXMonth:
				days = Number(this.daysBetweenFullRunsMonthInputValue);
				break;
		}

		if (isNaN(days)) {
			return;
		}

		days = Math.ceil(days);
		days = days > 99 ? 99 : days < 1 ? 1 : days;

		this.value = {
			type,
			daysBetweenFullRuns: days,
		};
		this.changed.emit(this.value);

		if (closePanel) {
			this.overlayPanelSignal$()?.[0]?.hide();
		}
	}

	selectPolicy(type: TiaCiPolicyType | TiaManualPolicyType): void {
		this.value = {
			type
		};
		this.changed.emit(this.value);
		this.overlayPanelSignal$()?.[0]?.hide();
	}
}
