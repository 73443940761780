import { ChangeDetectionStrategy, Component, computed, inject, input, OnInit, output, Signal, viewChild } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { FormsModule } from '@angular/forms';
import { Condition, ISlQueryTotalsResponse, Operator, SlQueryRequestBuilderWeb } from '@sealights/sl-query-builder';
import { SlQueryRuleSetBuilder } from '@sealights/sl-query-builder/dist/src/core/sl-query-request-builder';
import { ITiaSettingsRequest, ITiaSettingsResponse, RecommendationMode, TiaSettingsV3SlSchema } from '@sealights/sl-shared-contracts';
import { InputNumberModule } from 'primeng/inputnumber';
import { SelectButtonModule } from 'primeng/selectbutton';
import { tap } from 'rxjs';

import { UserRolePermission } from '@@core/models/auth/auth-model';
import { FeatureService } from '@@core/services/features.service';
import { LDFeatureKey } from '@@core/services/launch-darkly.service';
import { ListFilterComponent } from '@@shared/list-filter/list-filter.component';
import { CustomColumnTemplateComponent } from '@@shared/sl-table/components/custom-templates/custom-column-template.component';
import { DynamicTableComponent } from '@@shared/sl-table/components/dynamic-table/dynamic-table.component';
import { ListFilterConfig, SLTableColumnType, SLTableConfig, SLTableFilterType, SLTableIds } from '@@shared/sl-table/models/sl-table.model';
import { AuthStore } from '@@shared/stores/auth-store/stores/auth.store';

import { FullRunPolicyV2 } from '../../models/tia-v2-settings-dialog.model';
import { TiaV2SettingsService } from '../../services/tia-v2-settings.service';
import { FullRunPolicyDropdownComponent } from '../full-run-policy-dropdown/full-run-policy-dropdown.component';

@Component({
	selector: 'sl-tia-v2-settings-table',
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [
		DynamicTableComponent,
		CustomColumnTemplateComponent,
		SelectButtonModule,
		FormsModule,
		InputNumberModule,
		ListFilterComponent,
		FullRunPolicyDropdownComponent
	],
	templateUrl: './tia-v2-settings-table.component.html',
	styleUrl: './tia-v2-settings-table.component.scss'
})
export class TiaV2SettingsTableComponent implements OnInit {
	readonly tiaSettingsRequestParams$ = input.required<ITiaSettingsRequest>();

	readonly table = viewChild<DynamicTableComponent<ITiaSettingsRequest, ITiaSettingsResponse>>(DynamicTableComponent);

	readonly testSelectionStatusChanged$ = output<void>();

	readonly tiaRecommendationModeFlagSignal$ = toSignal(inject(FeatureService).isFeatureOn(LDFeatureKey.TIA_MODE));
	readonly tiaTimeLineFlagSignal$ = toSignal(inject(FeatureService).isFeatureOn(LDFeatureKey.TIA_TIME_LINE));
	readonly shouldDisplayRecommendationModeColumn$ = computed(() =>
		this.tiaTimeLineFlagSignal$() && this.tiaRecommendationModeFlagSignal$()
	);
	readonly userRoleSignal$ = inject(AuthStore).getUserRole;
	readonly hasChangeTiaPermissionSignal$ = computed(() => UserRolePermission.TIA_SELECTION.includes(this.userRoleSignal$()));

	readonly hasChangeTiaPolicyPermissionSignal$ = computed(() => UserRolePermission.CHANGE_TIA_POLICIES.includes(this.userRoleSignal$()));

	readonly offOnOptions = [
		{
			label: 'Off',
			value: false
		},
		{
			label: 'On',
			value: true
		}
	];

	readonly recommendationModeOptions = [
		{
			label: 'Build',
			value: 'build'
		},
		{
			label: 'TSR',
			value: 'tsr'
		}
	];

	tableConfig$: Signal<SLTableConfig<ITiaSettingsRequest, ITiaSettingsResponse>>;

	readonly #tiaV2SettingsService = inject(TiaV2SettingsService);

	ngOnInit(): void {
		this.#tableConfig();
	}

	onTiaOnChange(value: boolean, definesDefaultSettings: boolean, branchName: string): void {
		const { appName, testStage } = this.tiaSettingsRequestParams$();
		this.#tiaV2SettingsService.setTestSelectionStatus({ appName, branchName, testStage }, value, definesDefaultSettings).pipe(
			tap(() => {
				this.table().refreshTable(false);
				this.testSelectionStatusChanged$.emit();
			})
		).subscribe();
	}

	onTiaOnForPrChange(value: boolean, definesDefaultSettings: boolean, branchName: string): void {
		const { appName, testStage } = this.tiaSettingsRequestParams$();
		this.#tiaV2SettingsService.setTestSelectionStatusForPR({ appName, branchName, testStage }, value, definesDefaultSettings).subscribe();
	}

	onActivateNextFullRunChange(value: boolean, branchName: string): void {
		const { appName, testStage } = this.tiaSettingsRequestParams$();
		this.#tiaV2SettingsService.setActivateNexFullRun({ appName, branchName, testStage }, value).subscribe();
	}

	onUpdateFullRunPolicy(value: FullRunPolicyV2, definesDefaultSettings: boolean, branchName: string): void {
		const { appName, testStage } = this.tiaSettingsRequestParams$();

		this.#tiaV2SettingsService.updateFullRunPolicy({ appName, branchName, testStage }, value, definesDefaultSettings)
			.pipe(
				tap(() => this.table().refreshTable(false))
			)
			.subscribe();
	}

	onRecommendationModeChange(value: RecommendationMode, definesDefaultSettings: boolean, branchName: string): void {
		const { appName, testStage } = this.tiaSettingsRequestParams$();

		this.#tiaV2SettingsService.updateRecommendationMode({ appName, branchName, testStage }, value, definesDefaultSettings)
			.pipe(
				tap(() => this.table().refreshTable(false))
			)
			.subscribe();
	}

	#tableConfig(): void {
		this.tableConfig$ = computed(() => {
			const branchRuleSetBuilder = this.#createBranchRuleSetBuilder(this.tiaSettingsRequestParams$().branchName);

			return {
				id: SLTableIds.TIA_SETTINGS_TABLE,
				className: 'tia-settings-table',
				dataSourceConfig: {
					apiEndpoint: TiaSettingsV3SlSchema.schema.route().slice(5),
					defaultQuery: new SlQueryRequestBuilderWeb<ITiaSettingsRequest, ITiaSettingsResponse>().withRuleSet(
						new SlQueryRuleSetBuilder<ITiaSettingsRequest, ITiaSettingsResponse>(Condition.And)
							.withRule('appName', Operator.Equals, this.tiaSettingsRequestParams$().appName)
							.withRule('testStage', Operator.Equals, this.tiaSettingsRequestParams$().testStage)
							.withRuleSet(branchRuleSetBuilder)
					).done()
				},
				features: {
					resizableColumns: true,
					filtering: true,
					hideSettings: true,
					sorting: true,
					removeFilterStateOnDestroy: true,
				},
				columnConfig: {
					columnDefs: [
						{
							field: 'branchName',
							order: 0,
							type: SLTableColumnType.CUSTOM,
							header: {
								displayName: 'Branch',
							},
							metaData: {
								filter: {
									type: SLTableFilterType.LIST,
									emitEventAs: 'query',
									ui: {
										displayMode: 'list',
										filter: true
									},
									field: 'branchName',
									dynamic: {
										dataSourceConfig: {
											apiEndpoint: TiaSettingsV3SlSchema.schema.totalsRoute().slice(5),
											defaultQuery: new SlQueryRequestBuilderWeb<ITiaSettingsRequest, ITiaSettingsResponse>().withRuleSet(
												new SlQueryRuleSetBuilder<ITiaSettingsRequest, ITiaSettingsResponse>(Condition.And)
													.withRule('appName', Operator.Equals, this.tiaSettingsRequestParams$().appName)
													.withRule('testStage', Operator.Equals, this.tiaSettingsRequestParams$().testStage)
											)
												.withCountsFor('branchName')
												.done(),
											responseTransformer: (res: { data: ISlQueryTotalsResponse }) => {
												res.data.totals = res.data.totals.filter(row => !!row.key);
												return res;
											}
										},
										label: 'branchName',
										value: 'branchName'
									}
								} as ListFilterConfig<ITiaSettingsRequest, ITiaSettingsResponse>
							},
							isFilterable: true,
							isSortable: true
						},
						{
							field: 'tiaOn',
							order: 1,
							type: SLTableColumnType.CUSTOM,
							header: {
								displayName: 'TIA\'s status',
							},
							isFilterable: false,
							isSortable: true
						},
						{
							field: 'tiaOnForPr',
							order: 2,
							type: SLTableColumnType.CUSTOM,
							header: {
								displayName: 'TIA\'s status for PR',
							},
							isFilterable: false,
							isSortable: true
						},
						{
							field: 'fullRunPolicy',
							order: 3,
							type: SLTableColumnType.CUSTOM,
							header: {
								displayName: 'Full-run policy',
								icon: 'info',
								tooltip: 'Running all of your tests periodically is crucial for the accuracy of the TIA\n (for new tests, changes in framework & configuration files,\n deleted or renamed tests, etc.)'
							},
							isFilterable: false,
							isSortable: false
						},
						{
							field: 'activateNextFullRun',
							order: 4,
							type: SLTableColumnType.CUSTOM,
							header: {
								displayName: 'Single activate next full-run',
								icon: 'info',
								tooltip: 'The next time a full run will occur\n (meaning, running all tests) will be according to the the full run policy,\n unless you manually set the next test execution as a full run.'
							},
							isFilterable: false,
							isSortable: true
						},
						{

							field: 'recommendationMode',
							order: 1,
							isVisible: this.shouldDisplayRecommendationModeColumn$(),
							type: SLTableColumnType.CUSTOM,
							header: {
								displayName: 'Recommendation Mode',
							},
							isFilterable: false,
							isSortable: true
						}
					],
					ignoredColumns: ['definesDefaultSettings', 'noCodeChangePolicy', 'calculateChangesFrom', 'closeExecutionTimeoutSec']
				}
			};
		});
	}

	#createBranchRuleSetBuilder(branchName: string): SlQueryRuleSetBuilder<ITiaSettingsRequest, ITiaSettingsResponse> {
		const ruleSetBuilder = new SlQueryRuleSetBuilder<ITiaSettingsRequest, ITiaSettingsResponse>(Condition.And);

		if (branchName) {
			ruleSetBuilder.withRule('branchName', Operator.Equals, this.tiaSettingsRequestParams$().branchName);
		}

		return ruleSetBuilder;
	}
}
