import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { RecommendationMode } from '@sealights/sl-shared-contracts';
import { Observable } from 'rxjs';

import { APP_CONFIG } from '@@shared/providers/application-config-provider/application-config-provider.model';

import { FullRunPolicyV2, TestSelectionStatusCountRequest, TestSelectionStatusCountResponse, UpdateActivateNexFullRunRequest, UpdateRecommendationModeRequest, UpdateTestSelectionStatusForPrRequest, UpdateTestSelectionStatusRequest } from '../models/tia-v2-settings-dialog.model';

@Injectable({
	providedIn: 'root'
})
export class TiaV2SettingsService {
	readonly #httpClient = inject(HttpClient);
	readonly #apiBaseUrl = inject(APP_CONFIG)?.uri?.apiBaseUrl;

	getTestSelectionStatusCount(reqParam: TestSelectionStatusCountRequest): Observable<TestSelectionStatusCountResponse> {
		const url = `${this.#apiBaseUrl}v3/tia/settings/apps/${encodeURIComponent(reqParam.appName)}/test-stages/${reqParam.testStage}/test-selection-status/count`;
		return this.#httpClient.get<TestSelectionStatusCountResponse>(url);
	}

	setTestSelectionStatus(reqParam: UpdateTestSelectionStatusRequest, value: boolean, definesDefaultSettings: boolean): Observable<void> {
		if (definesDefaultSettings) {
			const url = `${this.#apiBaseUrl}v2/tia/test-selection/test-selection-default-status/${encodeURIComponent(reqParam.appName)}/${encodeURIComponent(reqParam.testStage)}`;
			return this.#httpClient.put<void>(url, { testSelectionDefaultStatus: value });

		} else {
			const url = `${this.#apiBaseUrl}v1/test-selection/${encodeURIComponent(reqParam.appName)}/${encodeURIComponent(reqParam.branchName)}/${encodeURIComponent(reqParam.testStage)}`;
			return this.#httpClient.put<void>(url, { enabled: value });
		}
	}

	setTestSelectionStatusForPR(reqParam: UpdateTestSelectionStatusForPrRequest, value: boolean, definesDefaultSettings: boolean): Observable<void>  {
		if (definesDefaultSettings) {
			const url = `${this.#apiBaseUrl}v3/tia/settings/apps/${encodeURIComponent(reqParam.appName)}/test-stages/${encodeURIComponent(reqParam.testStage)}/pull-request/default/status`;
			return this.#httpClient.put<void>(url, { enabled: value });

		} else {
			const url = `${this.#apiBaseUrl}v3/tia/settings/apps/${encodeURIComponent(reqParam.appName)}/branches/${encodeURIComponent(reqParam.branchName)}/test-stages/${encodeURIComponent(reqParam.testStage)}/pull-request/status`;
			return this.#httpClient.put<void>(url, { enabled: value });
		}
	}

	setActivateNexFullRun(reqParam: UpdateActivateNexFullRunRequest, value: boolean): Observable<void>  {
		const url = `${this.#apiBaseUrl}v1/test-selection/schedule-full-run/${encodeURIComponent(reqParam.appName)}/${encodeURIComponent(reqParam.branchName)}/${encodeURIComponent(reqParam.testStage)}`;
		return this.#httpClient.put<void>(url, { enable: value });
	}

	updateFullRunPolicy(reqParam: UpdateActivateNexFullRunRequest, fullRunPolicyV2: FullRunPolicyV2, definesDefaultSettings: boolean): Observable<void> {
		if (definesDefaultSettings) {
			const url = `${this.#apiBaseUrl}v3/tia/settings/apps/${encodeURIComponent(reqParam.appName)}/test-stages/${encodeURIComponent(reqParam.testStage)}/full-run-policy`;
			return this.#httpClient.put<void>(url, fullRunPolicyV2);

		} else {
			const url = `${this.#apiBaseUrl}v3/tia/settings/apps/${encodeURIComponent(reqParam.appName)}/branches/${encodeURIComponent(reqParam.branchName)}/test-stages/${encodeURIComponent(reqParam.testStage)}/full-run-policy`;
			return this.#httpClient.put<void>(url, fullRunPolicyV2);
		}
	}

	updateRecommendationMode(reqParam: UpdateRecommendationModeRequest, recommendationMode: RecommendationMode, definesDefaultSettings: boolean): Observable<void> {
		if (definesDefaultSettings) {
			const url = `${this.#apiBaseUrl}v3/tia/settings/apps/${encodeURIComponent(reqParam.appName)}/test-stages/${encodeURIComponent(reqParam.testStage)}/recommendations-mode`;
			return this.#httpClient.put<void>(url, { type: recommendationMode });

		} else {
			const url = `${this.#apiBaseUrl}v3/tia/settings/apps/${encodeURIComponent(reqParam.appName)}/branches/${encodeURIComponent(reqParam.branchName)}/test-stages/${encodeURIComponent(reqParam.testStage)}/recommendations-mode`;
			return this.#httpClient.put<void>(url, { type: recommendationMode });
		}
	}
}
