import { ChangeDetectionStrategy, Component, computed, inject, OnInit, Signal, signal, WritableSignal } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatIcon } from '@angular/material/icon';
import { ITiaSettingsRequest } from '@sealights/sl-shared-contracts';
import { MenuItem } from 'primeng/api';
import { tap } from 'rxjs';

import { BreadcrumbsComponent } from '@@shared/breadcrumbs';
import { BreadcrumbSizeOption } from '@@shared/breadcrumbs/models/breadcrumbs.model';
import { DialogComponent } from '@@shared/dialog';

import { TestSelectionStatusCountResponse } from '../../models/tia-v2-settings-dialog.model';
import { TiaV2SettingsService } from '../../services/tia-v2-settings.service';
import { TiaV2SettingsTableComponent } from '../tia-v2-settings-table/tia-v2-settings-table.component';

@Component({
	selector: 'sl-tia-v2-settings-dialog',
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [
		DialogComponent,
		BreadcrumbsComponent,
		MatIcon,
		TiaV2SettingsTableComponent
	],
	templateUrl: './tia-v2-settings-dialog.component.html'
})
export class TiaV2SettingsDialogComponent implements OnInit {
	breadcrumbsItems$: Signal<MenuItem[]>;

	readonly testSelectionStatusCount$: WritableSignal<TestSelectionStatusCountResponse | undefined> = signal<TestSelectionStatusCountResponse | undefined>(undefined);
	readonly dialogTitle = 'TIA Configurations';
	readonly breadcrumbSizeOption = BreadcrumbSizeOption;

	readonly dialogData = inject(MAT_DIALOG_DATA) as ITiaSettingsRequest;
	readonly #tiaV2SettingsService = inject(TiaV2SettingsService);

	ngOnInit(): void {
		this.initBreadcrumbsItems();
		this.getTestSelectionStatusCount();
	}

	constructBranchTotalsCaption(testSelectionStatusCountResponse: TestSelectionStatusCountResponse): string {
		if (!testSelectionStatusCountResponse) {
			return '';
		}

		return `${testSelectionStatusCountResponse.data.totalTiaOn} of ${this.testSelectionStatusCount$().data.total} branches are with TIA on`;
	}

	onTestSelectionStatusChanged(): void {
		this.getTestSelectionStatusCount();
	}

	getTestSelectionStatusCount(): void {
		this.#tiaV2SettingsService.getTestSelectionStatusCount(this.dialogData)
			.pipe(tap(res => this.testSelectionStatusCount$.set(res)))
			.subscribe();
	}

	initBreadcrumbsItems(): void {
		this.breadcrumbsItems$ = computed(() => [
			{
				label: this.dialogData.appName
			},
			{
				label: this.dialogData.testStage
			},
			{
				label: this.constructBranchTotalsCaption(this.testSelectionStatusCount$()),
				isLoading: !this.testSelectionStatusCount$()
			}
		]);
	}
}
